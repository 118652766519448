<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Communications">
                    <template v-slot:description>
                        <div>import { HbCommunications } from 'hummingbird-aviary';</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card class="mt-4 mb-6">
            <template v-slot:title>
                HbCommunication Sandbox + Code
            </template>

            <div class="ma-4">
                
                <v-row no-gutters>

                    <hb-communication
                        :preview="preview"
                        :elevation="elevation"
                        :active="active"
                        :dismissable="dismissable"
                        :expanded.sync="expanded"
                        :id="id"
                        :type="type"
                        :system-generated="systemGenerated"
                        :disable-audit-trail="disableAuditTrail"
                        :audit-trail-header="auditTrailHeader"
                        :admin-name="adminName"
                        :audit-trail-date-time="auditTrailDateTime"
                        :disable-audit-trail-date-time="disableAuditTrailDateTime"
                        :contact-id="contactId"
                        :contact-is-account="contactIsAccount"
                        :contact-is-business="contactIsBusiness"
                        :contact-name="contactName"
                        :contact-email="contactEmail"
                        :contact-phone="contactPhone"
                        :contact-status="contactStatus"
                        :space-id="spaceId"
                        :reservation-id="reservationId"
                        :space="space"
                        :space-type="spaceType"
                        :icon.sync="icon"
                        :title.sync="title"
                        :note-category="noteCategory"
                        :delivery-method="deliveryMethod"
                        :delivered-by="deliveredBy"
                        :document-id="documentId"
                        :document-name="documentName"
                        :tracking-id="trackingId"
                        :tracking-id-clickable="trackingIdClickable"
                        :tracking-url="trackingUrl"
                        :subject="subject"
                        :property="property"
                        :source="source"
                        :notes="notes"
                        :time="time"
                        :has-images="hasImages"
                        :has-attachments="hasAttachments"
                        :has-notes="hasNotes"
                        :unread="unread"
                        :alternate-contact="alternateContact"
                        :alternate-contact-id="alternateContactId"
                        :alternate-contact-type="alternateContactType"
                        :alternate-contact-name="alternateContactName"
                        :alternate-contact-email="alternateContactEmail"
                        :alternate-contact-phone="alternateContactPhone"
                        :outgoing="outgoing"
                        :hide-playback="hidePlayback"
                        :playback-url="playback_url"
                        :hide-voicemail="hideVoicemail"
                        :voicemail-url="voicemail_url"
                        :hide-actions="hideActions"
                        :disable-pinning="disablePinning"
                        :disable-status-preview="disableStatusPreview"
                        :pinned="pinned"
                        :status="status"
                        :status-time="statusTime"
                        :error-description="errorDescription"
                        :hide-error-description="hideErrorDescription"
                        :hide-resend-button="hideResendButton"
                        :hide-reply-button="hideReplyButton"
                        :hide-call-back-button="hideCallBackButton"
                        :hide-notes-button="hideNotesButton"
                        :hide-view-print-button="hideViewPrintButton"
                        @click="handleClick"
                        @pinned-icon-clicked="handlePinned"
                        @dismiss="handleDismiss"
                        @tracking-id-clicked="handleTrackingIdClick"
                        @playback-played="handlePlaybackPlayed"
                        @playback-paused="handlePlaybackPaused"
                        @voicemail-played="handleVoicemailPlayed"
                        @voicemail-paused="handleVoicemailPaused"
                        @call-back="handleCallBack"
                        @reply-email="handleReplyEmail"
                        @resend-email="handleResendEmail"
                        @resend-text="handleResendText"
                        @reply-text="handleReplyText"
                        @view-notes="handleViewNotes"
                        @add-notes="handleAddNotes"
                        @view-print="handleViewPrint"
                        @width-change="handleWidthChange"
                    >
                        <template v-slot:default v-if="message">
                            {{ message }}
                        </template>
                        <template v-slot:leftActions v-if="hasAttachments && (type == 'email in' || type == 'email out' || type == 'text in' || type == 'text out' || type == 'system email' || type == 'system text')">
                            <v-row no-gutters v-if="exampleAttachments.length > 2 && !showMoreAttachments">
                                <hb-menu v-for="(attachment, i) in exampleAttachments.slice(0, 2)" :key="'attachment' + i">
                                    <template v-slot:menu-activator>
                                        <hb-chip pointer :max-width="communicationWidthHalved">
                                            {{ attachment.name }}
                                        </hb-chip>
                                    </template>

                                    <v-list>

                                        <v-list-item :href="attachment.url" target="_blank">
                                            <v-list-item-title>View / Print</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item :href="attachment.url" download>
                                            <v-list-item-title>
                                                Download
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click="saveFileToDocuments()">
                                            <v-list-item-title>Save to Documents</v-list-item-title>
                                        </v-list-item>

                                    </v-list>
                                </hb-menu>
                                <hb-chip pointer @chip-click="showMoreAttachments = true" :max-width="communicationWidthHalved">
                                    + {{ exampleAttachments.length - 2 }}
                                </hb-chip>
                            </v-row>
                            <v-row no-gutters v-else>
                                <hb-menu v-for="(attachment, i) in exampleAttachments" :key="'attachment_expanded' + i">
                                    <template v-slot:menu-activator>
                                        <hb-chip pointer :max-width="communicationWidthHalved">
                                            {{ attachment.name }}
                                        </hb-chip>
                                    </template>

                                    <v-list>

                                        <v-list-item :href="attachment.url" target="_blank">
                                            <v-list-item-title>View / Print</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item :href="attachment.url" download>
                                            <v-list-item-title>Download</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click="saveFileToDocuments()">
                                            <v-list-item-title>Save to Documents</v-list-item-title>
                                        </v-list-item>

                                    </v-list>
                                </hb-menu>
                            </v-row>
                        </template>
                    </hb-communication>

                    <hb-notification
                        v-model="cautionNotification"
                        v-if="cautionNotification"
                        type="caution"
                        class="mt-3"
                        title="Mandatory Next Dev Actions:"
                    >
                        {{ cautionNotificationMessage }}
                    </hb-notification>

                </v-row>

                <v-row no gutters>

                    <v-col cols="6" no-gutters>
                        <hb-card class="mt-2" no-title width="552">

                            <hb-form label="preview">
                                <HbSwitch
                                    v-model="preview"
                                    :label="preview ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="elevation">
                                <HbSwitch
                                    v-model="elevation"
                                    :label="elevation ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="active">
                                <HbSwitch
                                    v-model="active"
                                    :label="active ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="preview" label="dismissable">
                                <HbSwitch
                                    v-model="dismissable"
                                    :label="dismissable ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="!preview" label="expanded">
                                <HbSwitch
                                    v-model="expanded"
                                    :label="expanded ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="type">
                                <HbSelect
                                    v-model="type"
                                    :items="typeItems"
                                    placeholder="Select Type"
                                />
                            </hb-form>

                            <hb-form v-if="!preview && (type == 'email out' || type == 'text out' || type == 'document' || type == 'note')" label="system-generated">
                                <HbSwitch
                                    v-model="systemGenerated"
                                    :label="systemGenerated ? 'true' : 'false/null'"
                                    :disabled="disableAuditTrail"
                                />
                            </hb-form>

                            <hb-form v-if="!preview && type !== 'missed' && type !== 'voicemail' && type !== 'text in' && type !== 'email in'" label="disable-audit-trail">
                                <HbSwitch
                                    v-model="disableAuditTrail"
                                    :label="disableAuditTrail ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="!preview && type !== 'missed' && type !== 'voicemail' && type !== 'text in' && type !== 'email in'" label="audit-trail-header" tooltip-body="This will override the default audit trail headers. You usually should not need to use this prop.">
                                <HbTextField
                                    v-model="auditTrailHeader"
                                    placeholder="Enter Audit Trail Header"
                                    :disabled="disableAuditTrail"
                                />
                            </hb-form>

                            <hb-form v-if="!preview && type !== 'missed' && type !== 'voicemail' && type !== 'text in' && type !== 'email in'" label="admin-name">
                                <HbTextField
                                    v-model="adminName"
                                    placeholder="Enter Admin Name"
                                />
                            </hb-form>

                            <hb-form v-if="!preview && type !== 'missed' && type !== 'voicemail' && type !== 'text in' && type !== 'email in'" label="audit-trail-date-time">
                                <HbTextField
                                    v-model="auditTrailDateTime"
                                    placeholder="Enter Audit Trail Date Time"
                                    :disabled="disableAuditTrail"
                                />
                            </hb-form>

                            <hb-form v-if="!preview && type !== 'missed' && type !== 'voicemail' && type !== 'text in' && type !== 'email in'" label="disable-audit-trail-date-time">
                                <HbSwitch
                                    v-model="disableAuditTrailDateTime"
                                    :label="disableAuditTrailDateTime ? 'true' : 'false/null'"
                                    :disabled="disableAuditTrail"
                                />
                            </hb-form>

                            <hb-form label="icon">
                                <HbTextField
                                    v-model="icon"
                                    placeholder="Enter Icon"
                                />
                            </hb-form>

                            <hb-form label="title">
                                <HbTextField
                                    v-model="title"
                                    placeholder="Enter Title"
                                />
                            </hb-form>

                            <hb-form v-if="type === 'note'" label="note-category">
                                <HbTextField
                                    v-model="noteCategory"
                                    placeholder="Enter Note Category"
                                />
                            </hb-form>

                            <hb-form label="id">
                                <HbTextField
                                    v-model="id"
                                    placeholder="Enter ID"
                                />
                            </hb-form>

                            <hb-form label="contact-id">
                                <HbTextField
                                    v-model="contactId"
                                    placeholder="Enter Contact ID"
                                />
                            </hb-form>

                            <hb-form label="contact-is-account">
                                <HbSwitch
                                    v-model="contactIsAccount"
                                    :label="contactIsAccount ? 'true' : 'false/null'"
                                    :disabled="contactIsBusiness"
                                />
                            </hb-form>

                            <hb-form label="contact-is-business">
                                <HbSwitch
                                    v-model="contactIsBusiness"
                                    :label="contactIsBusiness ? 'true' : 'false/null'"
                                    :disabled="contactIsAccount"
                                />
                            </hb-form>

                            <hb-form label="contact-name">
                                <HbTextField
                                    v-model="contactName"
                                    placeholder="Enter Contact Name"
                                />
                            </hb-form>

                            <hb-form label="contact-email">
                                <HbTextField
                                    v-model="contactEmail"
                                    placeholder="Enter Contact Email"
                                />
                            </hb-form>

                            <hb-form label="contact-phone">
                                <HbTextField
                                    v-model="contactPhone"
                                    placeholder="Enter Contact Phone"
                                />
                            </hb-form>

                            <hb-form label="contact-status">
                                <HbTextField
                                    v-model="contactStatus"
                                    placeholder="Enter Contact Status"
                                />
                            </hb-form>

                            <hb-form label="space-id">
                                <HbTextField
                                    v-model="spaceId"
                                    placeholder="Enter Space ID"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'reservation'" label="reservation-id">
                                <HbTextField
                                    v-model="reservationId"
                                    placeholder="Enter Reservation ID"
                                />
                            </hb-form>

                            <hb-form label="space">
                                <HbTextField
                                    v-model="space"
                                    placeholder="Enter Space"
                                />
                            </hb-form>

                            <hb-form label="space-type">
                                <HbSelect
                                    v-model="spaceType"
                                    :items="spaceTypeItems"
                                    item-text="type"
                                    item-value="type"
                                    placeholder="Enter Space Type"
                                />
                            </hb-form>

                            <hb-form label="delivered-by" v-if="type == 'system email' || type == 'document'">
                                <HbTextField
                                    v-model="deliveredBy"
                                    placeholder="Enter Delivered by"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'document'" label="delivery-method">
                                <HbTextField
                                    v-model="deliveryMethod"
                                    placeholder="Enter Delivery Method"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'document'" label="document-id">
                                <HbTextField
                                    v-model="documentId"
                                    placeholder="Enter Document ID"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'document'" label="document-name">
                                <HbTextField
                                    v-model="documentName"
                                    placeholder="Enter Document Name"
                                />
                            </hb-form>

                            <hb-form label="subject" v-if="type == 'email in' || type == 'email out' || type == 'system email'">
                                <HbTextField
                                    v-model="subject"
                                    placeholder="Enter Subject"
                                />
                            </hb-form>

                            <hb-form label="property" v-if="type == 'call in' || type == 'call out'">
                                <HbTextField
                                    v-model="property"
                                    placeholder="Enter Property"
                                />
                            </hb-form>

                            <hb-form label="source" v-if="type == 'call in' || type == 'call out' || type == 'voicemail' || type == 'missed' || type == 'reservation'">
                                <HbTextField
                                    v-model="source"
                                    placeholder="Enter Source"
                                />
                            </hb-form>

                            <hb-form label="notes" v-if="type == 'call in' || type == 'call out'">
                                <HbTextField
                                    v-model="notes"
                                    placeholder="Enter Notes"
                                />
                            </hb-form>

                            <hb-form label="Default <slot>">
                                <HbTextarea
                                    v-model="message"
                                    placeholder="Enter Message"
                                />
                            </hb-form>

                        </hb-card>
                    
                    </v-col>

                    <v-col cols="6" no-gutters>

                        <hb-card class="mt-2" no-title width="552">

                            <hb-form v-if="lastEmittedEvent" label="Last Emitted Event">
                                {{ lastEmittedEvent }}
                            </hb-form>

                            <hb-form label="time">
                                <hb-radio-group v-model="setTime">
                    
                                    <HbRadio
                                        label="Today"
                                        value="today"
                                    />

                                    <HbRadio
                                        label="Yesterday"
                                        value="yesterday"
                                    />

                                    <HbRadio
                                        label="Two Days Ago"
                                        value="two days ago"
                                    />

                                </hb-radio-group>
                            </hb-form>

                            <hb-form label="has-images">
                                <HbSwitch
                                    v-model="hasImages"
                                    :label="hasImages ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="has-attachments">
                                <HbSwitch
                                    v-model="hasAttachments"
                                    :label="hasAttachments ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="show-more-attachments" v-if="hasAttachments && !preview && (type == 'email in' || type == 'email out' || type == 'text in' || type == 'text out' || type == 'system email' || type == 'system text')" tooltip-body="Custom slot prop for this example, i.e. not a native HbCommunication prop">
                                <HbSwitch
                                    v-model="showMoreAttachments"
                                    :label="showMoreAttachments ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="has-notes">
                                <HbSwitch
                                    v-model="hasNotes"
                                    :label="hasNotes ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="unread">
                                <HbSwitch
                                    v-model="unread"
                                    :label="unread ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="!preview" label="pinned">
                                <HbSwitch
                                    v-model="pinned"
                                    :disabled="disablePinning"
                                    :label="pinned ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="!preview" label="disable-pinning">
                                <HbSwitch
                                    v-model="disablePinning"
                                    :label="disablePinning ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'email out' || type == 'text out' || type == 'system email' || type == 'system text'" label="disable-status-preview">
                                <HbSwitch
                                    v-model="disableStatusPreview"
                                    :label="disableStatusPreview ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'email out' || type == 'text out' || type == 'system email' || type == 'system text' || type == 'document'" label="status">
                                <HbSelect
                                    v-model="status"
                                    :items="statusItems"
                                    placeholder="Enter Status"
                                    :disabled="disableStatusPreview"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'email out' || type == 'text out' || type == 'system email' || type == 'system text' || type == 'document'" label="status-time">
                                <hb-radio-group v-model="setStatusTime" :disabled="disableStatusPreview">
                    
                                    <HbRadio
                                        label="Today"
                                        value="today"
                                    />

                                    <HbRadio
                                        label="Yesterday"
                                        value="yesterday"
                                    />

                                    <HbRadio
                                        label="Two Days Ago"
                                        value="two days ago"
                                    />

                                </hb-radio-group>
                            </hb-form>

                            <hb-form v-if="!preview" label="hide-actions">
                                <HbSwitch
                                    v-model="hideActions"
                                    :label="hideActions ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="outgoing">
                                <HbSwitch
                                    v-model="outgoing"
                                    :label="outgoing ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="alternate-contact">
                                <HbSwitch
                                    v-model="alternateContact"
                                    :label="alternateContact ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="alternate-contact-id">
                                <HbTextField
                                    v-model="alternateContactId"
                                    placeholder="Enter Alternate Contact ID"
                                    :disabled="!alternateContact"
                                />
                            </hb-form>

                            <hb-form label="alternate-contact-type">
                                <HbTextField
                                    v-model="alternateContactType"
                                    placeholder="Enter Alternate Contact Type"
                                    :disabled="!alternateContact"
                                />
                            </hb-form>

                            <hb-form label="alternate-contact-name">
                                <HbTextField
                                    v-model="alternateContactName"
                                    placeholder="Enter Alternate Contact Name"
                                    :disabled="!alternateContact"
                                />
                            </hb-form>

                            <hb-form label="alternate-contact-email">
                                <HbTextField
                                    v-model="alternateContactEmail"
                                    placeholder="Enter Alternate Contact Email"
                                    :disabled="!alternateContact"
                                />
                            </hb-form>

                            <hb-form label="alternate-contact-phone">
                                <HbTextField
                                    v-model="alternateContactPhone"
                                    placeholder="Enter Alternate Contact Phone"
                                    :disabled="!alternateContact"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'document'" label="tracking-id">
                                <HbTextField
                                    v-model="trackingId"
                                    placeholder="Enter Tracking ID"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'document'" label="tracking-id-clickable">
                                <HbSwitch
                                    v-model="trackingIdClickable"
                                    :label="trackingIdClickable ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'document'" label="tracking-url">
                                <HbTextField
                                    v-model="trackingUrl"
                                    placeholder="Enter Tracking Url"
                                />
                            </hb-form>

                            <hb-form v-if="(type == 'call in' || type == 'call out') && !preview" label="hide-playback">
                                <HbSwitch
                                    v-model="hidePlayback"
                                    :label="hidePlayback ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="(type == 'call in' || type == 'call out') && !preview"  label="playback-url">
                                <hb-radio-group v-model="playbackRadioGroup" :disabled="hidePlayback">
                    
                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-12s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-12s.mp3"
                                    />

                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-6s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-6s.mp3"
                                    />

                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-9s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-9s.mp3"
                                    />
                                    
                                    <HbRadio
                                        label="undefined"
                                    />

                                </hb-radio-group>
                            </hb-form>

                            <hb-form v-if="type == 'voicemail' && !preview" label="hide-voicemail">
                                <HbSwitch
                                    v-model="hideVoicemail"
                                    :label="hideVoicemail ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'voicemail' && !preview" label="voicemail-url">
                                <hb-radio-group v-model="voicemailRadioGroup" :disabled="hideVoicemail">
                    
                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-12s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-12s.mp3"
                                    />

                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-6s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-6s.mp3"
                                    />

                                    <HbRadio
                                        label="'https://samplelib.com/lib/preview/mp3/sample-9s.mp3'"
                                        value="https://samplelib.com/lib/preview/mp3/sample-9s.mp3"
                                    />
                                    
                                    <HbRadio
                                        label="undefined"
                                    />

                                </hb-radio-group>
                            </hb-form>

                            <hb-form v-if="type == 'document' && (status == 'Bounced' || status == 'Error' || status == 'Failed' || status == 'Resolved')" label="error-description">
                                <HbTextField
                                    v-model="errorDescription"
                                    placeholder="Enter Error Description"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'document' && (status == 'Bounced' || status == 'Error' || status == 'Failed' || status == 'Resolved') && !preview" label="hide-error-description">
                                <HbSwitch
                                    v-model="hideErrorDescription"
                                    :label="hideErrorDescription ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="(type == 'email out' || type == 'text out' || type == 'system email' || type == 'system text') && !preview" label="hide-resend-button">
                                <HbSwitch
                                    v-model="hideResendButton"
                                    :label="hideResendButton ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="(type == 'email in' || type == 'text in') && !preview" label="hide-reply-button">
                                <HbSwitch
                                    v-model="hideReplyButton"
                                    :label="hideReplyButton ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="(type == 'voicemail' || type == 'missed' || type == 'call in' || type == 'call out' ) && !preview" label="hide-call-back-button">
                                <HbSwitch
                                    v-model="hideCallBackButton"
                                    :label="hideCallBackButton ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'document' && !preview" label="hide-notes-button">
                                <HbSwitch
                                    v-model="hideNotesButton"
                                    :label="hideNotesButton ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="type == 'document' && !preview" label="hide-view-print-button">
                                <HbSwitch
                                    v-model="hideViewPrintButton"
                                    :label="hideViewPrintButton ? 'true' : 'false/null'"
                                />
                            </hb-form>

                        </hb-card>

                    </v-col>

                    <v-col cols="12" no-gutters class="mb-1">
                        <hb-card color="#E0F5F5" title="Code + Data">
                            <pre class="px-4" :style="codeExpanded1 ? 'height:auto' : 'height:320px;'">
                                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="codeExpanded1 = !codeExpanded1">
                                    {{ codeExpanded1 ? 'Collapse Code' : 'Expand Code' }}
                                    <hb-icon>mdi-chevron-down</hb-icon>
                                </div>
&lt;hb-communication
    :preview="{{ preview }}"
    :elevation="{{ elevation }}"
    :active="{{ active }}"
    :dismissable="{{ dismissable }}"
    :expanded.sync="{{ expanded }}"
    id="{{ id }}"
    type="{{ type }}"
    :system-generated="{{ systemGenerated }}"
    :disable-audit-trail="{{ disableAuditTrail }}"
    :audit-trail-header="{{ auditTrailHeader }}"
    admin-name="{{ adminName }}"
    audit-trail-date-time="{{ auditTrailDateTime }}"
    :disable-audit-trail-date-time="{{ disableAuditTrailDateTime }}"
    contact-id="{{ contactId }}"
    :contact-is-account="{{ contactIsAccount }}"
    :contact-is-business="{{ contactIsBusiness }}"
    contact-name="{{ contactName }}"
    contact-email="{{ contactEmail }}"
    contact-phone="{{ contactPhone }}"
    contact-status="{{ contactStatus }}"
    space-id="{{ spaceId }}"
    reservation-id="{{ reservationId }}"
    space="{{ space }}"
    space-type="{{ spaceType }}"
    icon.sync="{{ icon }}"
    title.sync="{{ title }}"
    note-category="{{ noteCategory }}"
    delivery-method="{{ deliveryMethod }}"
    delivered-by="{{ deliveredBy }}"
    document-id="{{ documentId }}"
    document-name="{{ documentName }}"
    tracking-id="{{ trackingId }}"
    :tracking-id-clickable="{{ trackingIdClickable }}"
    tracking-url="{{ trackingUrl }}"
    subject="{{ subject }}"
    property="{{ property }}"
    source="{{ source }}"
    notes="{{ notes }}"
    time="{{ time }}"
    :has-images="{{ hasImages }}"
    :has-attachments="{{ hasAttachments }}"
    :has-notes="{{ hasNotes }}"
    :unread="{{ unread }}"
    :alternate-contact="{{ alternateContact }}"
    alternate-contact-id="{{ alternateContactId }}"
    alternate-contact-type="{{ alternateContactType }}"
    alternate-contact-name="{{ alternateContactName }}"
    alternate-contact-email="{{ alternateContactEmail }}"
    alternate-contact-phone="{{ alternateContactPhone }}"
    :outgoing="{{ outgoing }}"
    :hide-playback="{{ hidePlayback }}"
    playback-url="{{ playback_url }}"
    :hide-voicemail="{{ hideVoicemail }}"
    voicemail-url="{{ voicemail_url }}"
    :hide-actions="{{ hideActions }}"
    :disable-pinning="{{ disablePinning }}"
    :disable-status-preview="{{ disableStatusPreview }}"
    :pinned="{{ pinned }}"
    status="{{ status }}"
    status-time="{{ statusTime }}"
    error-description="{{ errorDescription }}"
    :hide-error-description="{{ hideErrorDescription }}"
    :hide-resend-button="{{ hideResendButton }}"
    :hide-reply-button="{{ hideReplyButton }}"
    :hide-call-back-button="{{ hideCallBackButton }}"
    :hide-notes-button="{{ hideNotesButton }}"
    :hide-view-print-button="{{ hideViewPrintButton }}"
    @click="handleClick"
    @pinned-icon-clicked="handlePinned"
    @dismiss="handleDismiss"
    @tracking-id-clicked="handleTrackingIdClick"
    @playback-played="handlePlaybackPlayed"
    @playback-paused="handlePlaybackPaused"
    @voicemail-played="handleVoicemailPlayed"
    @voicemail-paused="handleVoicemailPaused"
    @call-back="handleCallBack"
    @reply-email="handleReplyEmail"
    @resend-email="handleResendEmail"
    @resend-text="handleResendText"
    @reply-text="handleReplyText"
    @view-notes="handleViewNotes"
    @add-notes="handleAddNotes"
    @view-print="handleViewPrint"
    @width-change="handleWidthChange"
>
    &lt;template v-slot:default v-if="message">
         <span v-pre>{{ message }}</span>
    &lt;/template>
    &lt;template v-slot:leftActions v-if="hasAttachments &amp;&amp; (type == 'email in' || type == 'email out' || type == 'text in' || type == 'text out' || type == 'system email' || type == 'system text')">
        &lt;v-row no-gutters v-if="exampleAttachments.length > 2 &amp;&amp; !showMoreAttachments">
            &lt;hb-menu v-for="(attachment, i) in exampleAttachments.slice(0, 2)" :key="'attachment' + i">
                &lt;template v-slot:menu-activator>
                    &lt;hb-chip pointer :max-width="communicationWidthHalved">
                        <span v-pre>{{ attachment.name }}</span>
                    &lt;/hb-chip>
                &lt;/template>

                &lt;v-list>

                    &lt;v-list-item :href="attachment.url" target="_blank">
                        &lt;v-list-item-title>View / Print&lt;/v-list-item-title>
                    &lt;/v-list-item>

                    &lt;v-list-item :href="attachment.url" download>
                        &lt;v-list-item-title>
                            Download
                        &lt;/v-list-item-title>
                    &lt;/v-list-item>

                    &lt;v-list-item @click="saveFileToDocuments()">
                        &lt;v-list-item-title>Save to Documents&lt;/v-list-item-title>
                    &lt;/v-list-item>

                &lt;/v-list>
            &lt;/hb-menu>
            &lt;hb-chip pointer @chip-click="showMoreAttachments = true" :max-width="communicationWidthHalved">
                + <span v-pre>{{ exampleAttachments.length - 2 }}</span> more
            &lt;/hb-chip>
        &lt;/v-row>
        &lt;v-row no-gutters v-else>
            &lt;hb-menu v-for="(attachment, i) in exampleAttachments" :key="'attachment_expanded' + i">
                &lt;template v-slot:menu-activator>
                    &lt;hb-chip pointer :max-width="communicationWidthHalved">
                        <span v-pre>{{ attachment.name }}</span>
                    &lt;/hb-chip>
                &lt;/template>

                &lt;v-list>

                    &lt;v-list-item :href="attachment.url" target="_blank">
                        &lt;v-list-item-title>View / Print&lt;/v-list-item-title>
                    &lt;/v-list-item>

                    &lt;v-list-item :href="attachment.url" download>
                        &lt;v-list-item-title>Download&lt;/v-list-item-title>
                    &lt;/v-list-item>

                    &lt;v-list-item @click="saveFileToDocuments()">
                        &lt;v-list-item-title>Save to Documents&lt;/v-list-item-title>
                    &lt;/v-list-item>

                &lt;/v-list>
            &lt;/hb-menu>
        &lt;/v-row>
    &lt;/template>
&lt;/hb-communication>
                            </pre>
                        </hb-card>
                    
                    </v-col>

                </v-row>
            </div>

        </hb-card>

        <hb-card class="mt-4 mb-6">
            <template v-slot:title>
                How to Sync Multiple Cards Using an "activeIndex" Prop + Code
                <v-spacer></v-spacer>
                <HbSwitch class="pl-4 font-weight-regular" v-model="example.previewMode" :label="example.previewMode ? 'Preview Mode' : 'Default Mode'" />
            </template>

            <div class="ma-4">
                <v-row
                    no-gutters
                    v-for="(communication, i) in example.communications"
                    :key="'multiple_sync_' + i"
                    class="mb-3"
                >

                    <hb-communication
                        :active="i == example.activeIndex"
                        @click="example.activeIndex = i"
                        :preview="example.previewMode"
                        :expanded.sync="communication.expanded"
                        :type="communication.type"
                        :property="communication.property"
                        :source="communication.source"
                        :time="communication.time"
                        :playback-url="communication.playback_url"
                        :voicemail-url="communication.voicemail_url"
                        :pinned="communication.pinned"
                        :admin-name="communication.admin_name"
                        :contact-name="communication.contact_name"
                        :contact-status="communication.contact_status"
                        :outgoing="communication.outgoing"
                        @pinned-icon-clicked="communication.pinned = !communication.pinned"
                    >
                        {{ communication.message }}
                    </hb-communication>

                </v-row>

                <v-row no gutters>

                    <v-col cols="12" no-gutters class="mb-1">
                        <hb-card color="#E0F5F5" title="Code + Data">
                            <pre class="px-4" :style="codeExpanded2 ? 'height:auto' : 'height:320px;'">
                                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="codeExpanded2 = !codeExpanded2">
                                    {{ codeExpanded2 ? 'Collapse Code' : 'Expand Code' }}
                                    <hb-icon>mdi-chevron-down</hb-icon>
                                </div>
&lt;v-row
    no-gutters
    v-for="(communication, i) in example.communications"
    :key="'multiple_sync_' + i"
    class="mb-3"
>

    &lt;hb-communication
        :active="i == example.activeIndex"
        @click="example.activeIndex = i"
        :preview="example.previewMode"
        :expanded.sync="communication.expanded"
        :type="communication.type"
        :property="communication.property"
        :source="communication.source"
        :time="communication.time"
        :playback-url="communication.playback_url"
        :voicemail-url="communication.voicemail_url"
        :pinned="communication.pinned"
        :admin-name="communication.admin_name"
        :contact-name="communication.contact_name"
        :contact-status="communication.contact_status"
        :outgoing="communication.outgoing"
        @pinned-icon-clicked="communication.pinned = !communication.pinned"
    >
        <span v-pre>{{ communication.message }}</span>
    &lt;/hb-communication>

&lt;/v-row>

Data:
{{ $data.example }}
                            </pre>
                        </hb-card>
                    
                    </v-col>

                </v-row>
            </div>

        </hb-card>

        <hb-card title="HbCommunication Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbCommunication Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbCommunication Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="successError"
            type="success"
            :list="successErrorList"
            :description="successErrorDescription"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">
    import tokens from '../../aviary/tokens.js';
    import moment from 'moment';

    export default {
        name: "DesignSystemCommunications",
        data: function() {
            return {
                preview: true,
                elevation: false,
                active: false,
                dismissable: false,
                expanded: false,
                id: '1232432',
                type: 'call in',
                systemGenerated: false,
                disableAuditTrail: false,
                auditTrailHeader: '',
                auditTrailDateTime: 'Jun 14, 2024 @ 2:25pm ',
                disableAuditTrailDateTime: false,
                contactId: '453243543',
                contactIsAccount: false,
                contactIsBusiness: false,
                contactName: 'Neville Longbottom',
                contactEmail: 'nevillelongbottom@hogwarts.edu',
                contactPhone: '+1 (800) 222-2222',
                contactStatus: 'current',
                spaceId: '2345435436',
                reservationId: '2354354354645',
                space: '101',
                spaceType: 'storage',
                icon: 'mdi-phone-incoming',
                title: 'Call (In)',
                noteCategory: 'General',
                time: '12:00am',
                deliveryMethod: 'Registered Email™',
                deliveredBy: 'Rpost',
                documentId: '23462553625',
                documentName: 'California Default Notice #1',
                trackingId: '1234567890ABC',
                trackingIdClickable: true,
                trackingUrl: 'http://sandbox.tenant-platform.com/design-system',
                subject: 'This is where the subject goes.',
                property: 'SO124 - Pomona - 435 Airport Way',
                source: 'Yelp | Coupon: 50%OFF',
                notes: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaqxefhd.',
                message: 'Lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler.',
                hasImages: true,
                hasAttachments: true,
                hasNotes: true,
                unread: true,
                hideActions: false,
                pinned: false,
                disablePinning: false,
                disableStatusPreview: false,
                alternateContact: false,
                alternateContactId: '512522343',
                alternateContactType: 'Lienholder',
                alternateContactName: 'Ron Weasley',
                alternateContactEmail: 'ronweasley@hogwarts.edu',
                alternateContactPhone: '+1 (800) 555-5555',
                outgoing: false,
                playback_url: 'https://samplelib.com/lib/preview/mp3/sample-12s.mp3',
                voicemail_url: 'https://samplelib.com/lib/preview/mp3/sample-6s.mp3',
                status: 'Opened',
                statusTime: '12:00am',
                lastDownloadedTime: '12:00am',
                adminRole: 'manager',
                adminName: 'Bellatrix LeStrange',
                errorDescription: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
                hideErrorDescription: false,
                hideResendButton: false,
                hideReplyButton: false,
                hideCallBackButton: false,
                hideNotesButton: false,
                hideViewPrintButton: false,

                codeExpanded1: false,
                codeExpanded2: false,
                setTime: 'today',
                setStatusTime: 'today',
                setLastDownloadedTime: 'today',
                statusItems: [ 'Print & Mail', 'Bounced', 'Error', 'Failed', 'In Progress', 'Pending', 'Sent', 'Completed', 'Delivered', 'Opened', 'Resolved'],
                spaceTypeItems: tokens.icons.spaces,
                showMoreAttachments: false,
                exampleAttachments: [ 
                    {
                        name: "multiple_pdf_icon.png",
                        url: "/img/multiple_pdf_icon.png"
                    },
                    {
                        name: "cheque.png",
                        url: "/img/cc_icons/64/cheque.png"
                    },
                    {
                        name: "ach.png",
                        url: "/img/cc_icons/64/ach.png"
                    },
                    {
                        name: "credit_card.png",
                        url: "/img/cc_icons/64/credit_card.png"
                    },
                    {
                        name: "dollar.png",
                        url: "/img/cc_icons/64/dollar.png"
                    },
                ],

                example: {
                    previewMode: false,
                    activeIndex: null,
                    communications: [
                        {
                            expanded: false,
                            type: "call in",
                            property: "SO124 - Pomona - 435 Airport Way",
                            source: "Yelp | Coupon: 50%OFF",
                            time: "1:51pm",
                            playback_url : "https://samplelib.com/lib/preview/mp3/sample-6s.mp3",
                            pinned: false,
                            admin_role: "manager",
                            admin_name: "Bellatrix LeStrange",
                            message: "Lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler.",
                            contact_name: 'Neville Longbottom',
                            contact_status: 'current',
                        },
                        {
                            expanded: false,
                            type: "call out",
                            property: "SO124 - Pomona - 435 Airport Way",
                            source: "Youtube | Coupon: 25%OFF",
                            time: "Aug 14, 2023 @ 2:03pm",
                            playback_url : "https://samplelib.com/lib/preview/mp3/sample-12s.mp3",
                            pinned: false,
                            admin_role: "manager",
                            admin_name: "Bellatrix LeStrange",
                            message: "Rem Ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler.",
                            contact_name: 'George Weasley',
                            contact_status: 'delinquent',
                            outgoing: true,
                        },
                        {
                            expanded: false,
                            type: "voicemail",
                            property: "SO124 - Pomona - 435 Airport Way",
                            source: "Facebook | Coupon: 70%OFF",
                            time: "Sep 19, 2022, 2:03pm",
                            voicemail_url : "https://samplelib.com/lib/preview/mp3/sample-9s.mp3",
                            pinned: false,
                            message: "Orem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler.",
                            contact_name: 'Albus Dumbledore',
                            contact_status: 'active lien',
                        },
                    ],
                },

                cautionNotification: false,
                cautionNotificationMessage: '',
                successError: false,
                successErrorDescription: '',
                successErrorList: [],
                lastEmittedEvent: '',
                playbackRadioGroup: 'https://samplelib.com/lib/preview/mp3/sample-12s.mp3',
                voicemailRadioGroup: 'https://samplelib.com/lib/preview/mp3/sample-6s.mp3',
                hideVoicemail: false,
                hidePlayback: false,
                typeItems: ['call in', 'call out', 'missed', 'voicemail', 'email in', 'email out', 'text in', 'text out', 'document', 'note' ],
                communicationWidthHalved: 0,

                propHeaders: [
                    { text: 'Name', value: 'name', width: '140' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Has Payload', value: 'payload' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'preview', type: 'boolean', default: 'false', description: 'If set to true, this turns the communication into preview mode / view.' },
                    { name: 'elevation', type: 'boolean', default: 'false', description: 'If set to true, this turns on elevation/box-shadow. Should only be used for preview mode used in global notifications.' },
                    { name: 'active', type: 'boolean', default: 'false', description: 'If set to true, this applies the correct selected / focused border styling to the communication. Please see the syncing multiple communications example on this page for how to sync the active prop to display only one selected communication at a time.' },
                    { name: 'expanded', type: 'boolean', default: 'false', description: 'Important: This prop must be set with the ".sync" modifier to work correctly such as ":expanded.sync=etc...". This makes it so clicking on the expand and collapse icons will update the prop correctly. Please reference the code in the examples for exact usage. This prop expands and collpases the main content of the communication in default view / mode only. Preview view / mode is always collapsed.' },
                    { name: 'dismissable', type: 'boolean', default: 'false', description: 'If set to true, this enables an "mdi-close" icon in the top right corner in preview mode that emits the "@dismiss" event when clicked. This would likely only ever be used when the communication is used an an incoming system notification.' },
                    { name: 'type', type: 'string', default: 'undefined', description: 'Set this prop appropriately to display the proper visual stylings, information, and actions for the intended communication type. The available options are "call in", "call out", "missed", "voicemail", "email in", "email out", "text in", "text out", "document", and "note".' },
                    { name: 'system-generated', type: 'boolean', default: 'false', description: 'If set to true, this sets the audit trail header text to "System Generated".' },
                    { name: 'disable-audit-trail', type: 'boolean', default: 'false', description: 'If set to true, this disables the audit trail.' },
                    { name: 'audit-trail-header', type: 'string', default: 'undefined', description: 'Use this prop to override the default audit trail header text. You should usually never need to do this.' },
                    { name: 'admin-name', type: 'string', default: 'undefined', description: 'Pass the admin name of the hummingbird manager or user who created or is associated with the communication here to show up in the audit trail tooltip. This only shows up on certain communication types.' },
                    { name: 'audit-trail-date-time', type: 'string', default: 'undefined', description: 'Sets the audit trail date and time display text.' },
                    { name: 'disable-audit-trail-date-time', type: 'boolean', default: 'false', description: 'If set to true, this disables the audit trail date and time. Only set this to true if the time for "audit-trail-date-time" prop matches the actual communication send time for "time" prop.' },
                    { name: 'icon', type: 'string', default: 'undefined', description: 'Override the communication icon ("mdi-xxxx" format). You should not ever need to do this.' },
                    { name: 'icon-color', type: 'string', default: 'undefined', description: 'Override the communication icon color. You should not ever need to do this.' },
                    { name: 'title', type: 'string', default: 'undefined', description: 'Override the communication title text. You should not ever need to do this.' },
                    { name: 'note-category', type: 'string', default: 'undefined', description: 'Set the Note Category text (if it exists) to display in parentheses next to the title for "note" type only.' },
                    { name: 'title-color', type: 'string', default: 'undefined', description: 'Override the communication title text color. You should not ever need to do this.' },
                    { name: 'id', type: 'string', default: 'undefined', description: 'Pass the id of the communication into this prop. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'space-id', type: 'string', default: 'undefined', description: 'Pass the space id of the space associated with the communication into this prop. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'space', type: 'string', default: 'undefined', description: 'Pass the space name associated with the communication into this prop. This will only display on certain communication types.' },
                    { name: 'space-type', type: 'string', default: 'undefined', description: 'Pass the space type associated with the space into this prop. Refer to the design system "tokens.js" file for all possible space types that can be passed into this prop.' },
                    { name: 'reservation-id', type: 'string', default: 'undefined', description: 'Pass the reservation id of the reservation associated with the communication into this prop. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'subject', type: 'string', default: 'undefined', description: 'Pass the subject text into this prop. This will only display on certain communication types.' },
                    { name: 'property', type: 'string', default: 'undefined', description: 'If the property name associated communication is known, set it here. This will only display on certain communication types.' },
                    { name: 'source', type: 'string', default: 'undefined', description: 'If the source associated with the communication is known, set it here. This will only display on certain communication types.' },
                    { name: 'notes', type: 'string', default: 'undefined', description: 'If the communication has one specific note associated with it, set it here. This will only display on certain communication types.' },
                    { name: 'delivered-by', type: 'string', default: 'undefined', description: 'Pass the delivered by text into this prop. This will only display on certain communication types.' },
                    { name: 'delivery-method', type: 'string', default: 'undefined', description: 'Pass the delivery method text into this prop. This will only display on certain communication types.' },
                    { name: 'document-id', type: 'string', default: 'undefined', description: 'Pass the document id of the document into this prop. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'document-name', type: 'string', default: 'undefined', description: 'Pass the document name of the document into this prop. This will only display on certain communication types.' },
                    { name: 'time', type: 'string', default: 'undefined', description: 'Pass the time of the communication into this prop. Please see the examples on proper styling format of the time. The only options should be "h:mma" or "MMM D, YYYY [ @ ] h:mma" for anything else. You can use "moment.js" to format the time.' },
                    { name: 'has-images', type: 'boolean', default: 'false', description: 'When there are images in the message body of the communication, set this to true to show the images icon.' },
                    { name: 'has-attachments', type: 'boolean', default: 'false', description: 'When there are attachements to the communication, set this to true to show the paperclip icon.' },
                    { name: 'has-notes', type: 'boolean', default: 'false', description: 'When there are notes associated with the document or communication, set this to true to show the notes icon. This should only ever be used for "document" "type" communications which relates to the "View Notes" and "Add Notes" buttons in the action bar. This is different from the call notes area in the main content which is set via the "notes" prop.' },
                    { name: 'unread', type: 'boolean', default: 'false', description: 'If set to true, shows an orange dot which indicates that the communication is unread.' },
                    { name: 'pinned', type: 'boolean', default: 'false', description: 'If set to true, the pin icon changes to filled in, if set to false it will just be outlined.' },
                    { name: 'disable-pinning', type: 'boolean', default: 'false', description: 'If set to true, this hides the pin functionality.' },
                    { name: 'disable-status-preview', type: 'boolean', default: 'false', description: 'If set to true, this hides the communication status icon in the top right corner which only shows under certain conditions.' },
                    { name: 'hide-actions', type: 'boolean', default: 'false', description: 'If set to true, this hides the bottom actions area.' },
                    { name: 'tracking-id', type: 'string', default: 'undefined', description: 'Pass the tracking id of the document into this prop. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'tracking-url', type: 'string', default: 'undefined', description: 'Pass the tracking url for the document into this prop. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'tracking-id-clickable', type: 'boolean', default: 'false', description: 'Set this prop to true to enable the tracking id to be clickable and allow it to fire the "tracking-id-clicked" event when clicked. This also applies the proper blue link styling to the text.' },
                    { name: 'playback-url', type: 'string', default: 'undefined', description: 'Set the call playback url here if there is one and the call playback audio controls will show up. If there is no call playback, set this to empty/undefined to hide the audio controls. This will only display on certain communication types.' },
                    { name: 'voicemail-url', type: 'string', default: 'undefined', description: 'Set the voicemail url here if there is one and the voicemail audio controls will show up. If there is no voicemail, set this to empty/undefined to hide the audio controls. This will only display on certain communication types.' },
                    { name: 'hide-playback', type: 'boolean', default: 'false', description: 'Set to true to hide the playback call / buttons / timer. You should not ever need to do this as the functionality shows/hides based upon the "playback-url" prop, but it is available if needed.' },
                    { name: 'hide-voicemail', type: 'boolean', default: 'false', description: 'Set to true to hide the voicemail message / buttons / timer. You should not ever need to do this as the functionality shows/hides based upon the "voicemail-url" prop, but it is available if needed.' },
                    { name: 'status', type: 'string', default: 'undefined', description: 'Pass the status of the communication or document here. The "status-type" prop below is usually not necessary if you use standard general statuses such as "Print & Mail", "Bounced", "Error", "Failed", "In Progress", "Pending", "Sent", "Completed", "Delivered", "Opened", and "Resolved". These all have the "status-type" prop already preset and hardcoded to them.' },
                    { name: 'status-type', type: 'string', default: 'undefined', description: 'Pass the status type of the communication or document here if you need to manually override or set the color scheme for the status. You usually should not need to set this prop if you used a hardcoded general "status" as defined in the prop description above. The available types are "caution", "default", "error", "guidance", and "success" if needed.' },
                    { name: 'status-time', type: 'string', default: 'undefined', description: 'Pass the status time of the communication or document here. This is different from the "time" prop which is for when the communication was actually sent. This prop is for when the status of the communication was most recently updated. This will only display on certain communication types.' },
                    { name: 'outgoing', type: 'boolean', default: 'undefined', description: 'If set to true, this adds the "To: " string before the contact name in preview mode. This should be set to true and used for all outgoing communication types.' },
                    { name: 'contact-id', type: 'string', default: 'undefined', description: 'Pass the contact id of the contact associated with the communication into this prop. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'contact-is-account', type: 'boolean', default: 'false', description: 'If set to true, this will display the correct national account icon in the contact information area.' },
                    { name: 'contact-is-business', type: 'boolean', default: 'false', description: 'If set to true, this will display the correct business icon in the contact information area.' },
                    { name: 'contact-name', type: 'string', default: 'undefined', description: 'Pass the contact name of the contact associated with the communication into this prop. This only displays in preview mode / view. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'contact-email', type: 'string', default: 'undefined', description: 'Pass the contact email of the contact associated with the communication into this prop. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'contact-phone', type: 'string', default: 'undefined', description: 'Pass the contact phone number of the contact associated with the communication into this prop. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'contact-status', type: 'string', default: 'undefined', description: 'Pass the contact status of the contact associated with the communication into this prop. This only displays in preview mode / view.' },
                    { name: 'alternate-contact', type: 'boolean', default: 'false', description: 'If set to true, this will display the correct additional information and visual indicators for the alternate contact such as the "To:" and "From" fields. This boolean value will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'alternate-contact-id', type: 'string', default: 'undefined', description: 'Pass the contact id of the alternate contact associated with the communication into this prop. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'alternate-contact-type', type: 'string', default: 'undefined', description: 'Pass the alternate contact type here to display the correct information and visual indicators when "alternate-contact" prop is set to true. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'alternate-contact-name', type: 'string', default: 'undefined', description: 'Pass the alternate contact name here to display the correct information and visual indicators when "alternate-contact" prop is set to true. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'alternate-contact-email', type: 'string', default: 'undefined', description: 'Pass the alternate contact email here to display the correct information and visual indicators when "alternate-contact" prop is set to true. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'alternate-contact-phone', type: 'string', default: 'undefined', description: 'Pass the alternate contact phone number here to display the correct information and visual indicators when "alternate-contact" prop is set to true. This will output to the payload of certain events to make it easier for subsequent actions.' },
                    { name: 'error-description', type: 'string', default: 'undefined', description: 'Place the error description here. This only displays for "error" or "guidance" type statuses in expanded mode and only for "document", "email out", and "text out" types. In preview mode for those types, the error description will show as a tooltip description when hovering over the status in the right corner of the communication card. For expanded mode, "email out" and "text out" will continue to have this same behavior, but "document" type will hide the right corner status and instead display the status in the footer and add the error description underneath the footer for "error" and "guidance" type general statuses.' },
                    { name: 'hide-error-description', type: 'boolean', default: 'false', description: 'If set to true, this manually hides the error description section.' },
                    { name: 'hide-resend-button', type: 'boolean', default: 'false', description: 'If set to true, this manually hides the resend button.' },
                    { name: 'hide-reply-button', type: 'boolean', default: 'false', description: 'If set to true, this manually hides the reply button.' },
                    { name: 'hide-call-back-button', type: 'boolean', default: 'false', description: 'If set to true, this manually hides the call back icon button.' },
                    { name: 'hide-notes-button', type: 'boolean', default: 'false', description: 'If set to true, this manually hides the notes button.' },
                    { name: 'hide-view-print-button', type: 'boolean', default: 'false', description: 'If set to true, this manually hides the view / print button.' },
                ],
                slotItems: [
                    { name: 'default', description: 'This slot is where you would place the main message for the communication if needed.' },
                    { name: 'leftActions', description: 'This slot is where you can place code to manually override the default bottom left actions. Please be careful to style and space the content nicely if using this slot.' },
                    { name: 'rightActions', description: 'This slot is where you can place code to manually override the default bottom right actions. Please be careful to style and space the content nicely if using this slot.' },
                    { name: 'playbackTime', description: 'This slot is where you can override the default call playback time / timer. You should not need to use this, but this slot is available if needed.' },
                    { name: 'voicemailTime', description: 'This slot is where you can override the default voicemail time / timer. You should not need to use this, but this slot is available if needed.' },
                ],
                eventItems: [
                    { name: '@click', payload: 'Yes', description: 'Event emitted whenever anywhere in the communication is clicked.' },
                    { name: '@pinned-icon-clicked', payload: 'Yes', description: 'Event emitted when pin / unpin icon is clicked.' },
                    { name: '@dismiss', payload: 'Yes', description: 'Event emitted when close / dismiss icon is clicked.' },
                    { name: '@tracking-id-clicked', payload: 'Yes', description: 'Event emitted when tracking id in the action bar is clicked. Event only fires if "tracking-id-clickable" prop is set to true.' },
                    { name: '@playback-played', payload: 'No', description: 'Event emitted when the call playback is played or unpaused.' },
                    { name: '@playback-paused', payload: 'No', description: 'Event emitted when the call playback is paused.' },
                    { name: '@voicemail-played', payload: 'No', description: 'Event emitted when the voicemail is played or unpaused.' },
                    { name: '@voicemail-paused', payload: 'No', description: 'Event emitted when the voicemail is paused.' },
                    { name: '@call-back', payload: 'Yes', description: 'Event emitted when the call back icon button is clicked.' },
                    { name: '@reply-email', payload: 'Yes', description: 'Event emitted when the reply button is clicked in an "email in" type communication.' },
                    { name: '@reply-text', payload: 'Yes', description: 'Event emitted when the reply button is clicked in an "text in" type communication.' },
                    { name: '@resend-email', payload: 'Yes', description: 'Event emitted when the resend button is clicked in an "email out" type communication.' },
                    { name: '@resend-text', payload: 'Yes', description: 'Event emitted when the resend button is clicked in an "text out" type communication.' },
                    { name: '@view-notes', payload: 'Yes', description: 'Event emitted when the view notes button is clicked.' },
                    { name: '@add-notes', payload: 'Yes', description: 'Event emitted when the add notes button is clicked.' },
                    { name: '@view-print', payload: 'Yes', description: 'Event emitted when the view / print button is clicked.' },
                    { name: '@width-change', payload: 'Yes', description: 'Event emitted when the width of the communication has changed via creation or browser resize or other resize event. It will emit the numeric value of the width.' }
                ],
            };
        },
        created(){
            this.time = moment().format('h:mma');
        },
        methods: {
            handleClick(payload){

                if(!this.active){
                    this.active = true;
                    this.successErrorDescription = 'HbCommunication was clicked from an inactive state. Active state clicks will still emit a "@click" event, but the HbGlobalNotification notification for this playground will be manually turned off so as to not get in the way of testing:';
                    this.successErrorList = [
                        'Event: "@click"',
                        'Payload: ' + JSON.stringify(payload, null, 2),
                    ]
                    this.successError = true;

                    this.cautionNotificationMessage = 'For communication center, when "@click" event is fired, we then must manually or programatically set the "active" prop to true to apply the active teal visual border styling only if the "active" prop was previously set to false. For incoming notification communications, the "active" prop should not be used when the component is clicked. Also, if the communication "unread" prop is true, we must attempt to successfully save the read / unread status into the database. If the database change is successful, only then we must manually or programatically change the "unread" prop to false to remove the orange dot.';
                    this.cautionNotification = true;
                }
                if(this.unread){
                    this.unread = false;
                }
                this.lastEmittedEvent = '@click';
            },
            handlePinned(payload){
                this.pinned = !this.pinned;
                this.successErrorDescription = 'Pinned icon was clicked:';
                this.successErrorList = [
                    'Event: "@pinned-icon-clicked"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;

                this.cautionNotificationMessage = 'When "@pinned-icon-clicked" event is fired, we must attempt to successfully save the pin/unpinned status into the database. If the database change is successful, only then we must manually or programatically toggle the "pinned" prop to true or false.';
                this.cautionNotification = true;

                this.lastEmittedEvent = '@pinned-icon-clicked';
            },
            handleResendEmail(payload){
                this.successErrorDescription = 'Resend email button was clicked:';
                this.successErrorList = [
                    'Event: "@resend-email"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@reply-email';
            },
            handleResendText(payload){
                this.successErrorDescription = 'Resend text button was clicked:';
                this.successErrorList = [
                    'Event: "@resend-text"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@reply-text';
            },
            handleReplyEmail(payload){
                this.successErrorDescription = 'Reply to email button was clicked:';
                this.successErrorList = [
                    'Event: "@reply-email"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@reply-email';
            },
            handleReplyText(payload){
                this.successErrorDescription = 'Reply to text button was clicked:';
                this.successErrorList = [
                    'Event: "@reply-text"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@reply-text';
            },
            handleCallBack(payload){
                this.successErrorDescription = 'Call Back button was clicked:';
                this.successErrorList = [
                    'Event: "@call-back"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@call-back';
            },
            handleViewPrint(payload){
                this.successErrorDescription = 'View / Print button was clicked:';
                this.successErrorList = [
                    'Event: "@view-print"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@view-print';
            },
            handleTrackingIdClick(payload){
                this.successErrorDescription = 'Tracking ID was clicked:';
                this.successErrorList = [
                    'Event: "@tracking-id-clicked"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@tracking-id-clicked';
            },
            handlePlaybackPlayed(){
                this.successErrorDescription = 'Playback was played:';
                this.successErrorList = [
                    'Event: "@playback-played"'
                ]
                this.successError = true;
                this.lastEmittedEvent = '@playback-played';
            },
            handlePlaybackPaused(){
                this.successErrorDescription = 'Playback was paused:';
                this.successErrorList = [
                    'Event: "@playback-paused"'
                ]
                this.successError = true;
                this.lastEmittedEvent = '@playback-paused';
            },
            handleVoicemailPlayed(){
                this.successErrorDescription = 'Voicemail was played:';
                this.successErrorList = [
                    'Event: "@voicemail-played"'
                ]
                this.successError = true;
                this.lastEmittedEvent = '@voicemail-played';
            },
            handleVoicemailPaused(){
                this.successErrorDescription = 'Voicemail was paused:';
                this.successErrorList = [
                    'Event: "@voicemail-paused"'
                ]
                this.successError = true;
                this.lastEmittedEvent = '@voicemail-paused';
            },
            handleDismiss(payload){
                this.successErrorDescription = 'Dismiss/Close icon was clicked:';
                this.successErrorList = [
                    'Event: "@dismiss"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@dismiss';
            },
            handleViewNotes(payload){
                this.successErrorDescription = 'View Notes button was clicked:';
                this.successErrorList = [
                    'Event: "@view-notes"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@view-notes';
            },
            handleAddNotes(payload){
                this.successErrorDescription = 'Add Notes button was clicked:';
                this.successErrorList = [
                    'Event: "@add-notes"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@add-notes';
            },
            saveFileToDocuments(){
                this.successErrorDescription = "Attachment has been successfully saved to the contact's documents.";
                this.successErrorList = [];
                this.successError = true;
            },
            handleWidthChange(width){
                this.communicationWidthHalved = width / 2 + 'px';
                this.lastEmittedEvent = '@width-change';
            }
        },
        watch: {
            type(){
                this.icon = '';
                this.title = '';
            },
            setTime(){
                if(this.preview){
                    if(this.setTime == 'today'){
                        this.time = moment().format('h:mma');
                    } else if(this.setTime == 'yesterday'){
                        this.time = moment().subtract(1, 'days').format('MMM D, YYYY');
                    } else if(this.setTime == 'two days ago'){
                        this.time = moment().subtract(2, 'days').format('MMM D, YYYY');
                    }
                } else {
                    if(this.setTime == 'today'){
                        this.time = moment().format('h:mma');
                    } else if(this.setTime == 'yesterday'){
                        this.time = moment().subtract(1, 'days').format('MMM D, YYYY [ @ ] h:mma');
                    } else if(this.setTime == 'two days ago'){
                        this.time = moment().subtract(2, 'days').format('MMM D, YYYY [ @ ] h:mma');
                    }
                }
            },
            setStatusTime(){
                if(this.setStatusTime == 'today'){
                    this.statusTime = moment().format('h:mma');
                } else if(this.setStatusTime == 'yesterday'){
                    this.statusTime = moment().subtract(1, 'days').format('MMM D, YYYY [ @ ] h:mma');
                } else if(this.setStatusTime == 'two days ago'){
                    this.statusTime = moment().subtract(2, 'days').format('MMM D, YYYY [ @ ] h:mma');
                }
            },
            setLastDownloadedTime(){
                if(this.setLastDownloadedTime == 'today'){
                    this.lastDownloadedTime = moment().format('h:mma');
                } else if(this.setLastDownloadedTime == 'yesterday'){
                    this.lastDownloadedTime = moment().subtract(1, 'days').format('MMM D, YYYY [ @ ] h:mma');
                } else if(this.setLastDownloadedTime == 'two days ago'){
                    this.lastDownloadedTime = moment().subtract(2, 'days').format('MMM D, YYYY [ @ ] h:mma');
                }
            },
            voicemailRadioGroup(){
                if(this.voicemailRadioGroup == 3){
                    this.voicemail_url = '';
                } else {
                    this.voicemail_url = this.voicemailRadioGroup;
                }
            },
            'voicemail_url'(){
                if(this.voicemail_url){
                    this.voicemailRadioGroup = this.voicemail_url;
                } else {
                    this.voicemailRadioGroup = 3;
                }
            },
            playbackRadioGroup(){
                if(this.playbackRadioGroup == 3){
                    this.playback_url = '';
                } else {
                    this.playback_url = this.playbackRadioGroup;
                }
            },
            'playback_url'(){
                if(this.playback_url){
                    this.playbackRadioGroup = this.playback_url;
                } else {
                    this.playbackRadioGroup = 3;
                }
            },
            deliveryMethod(){
                if(this.type == 'document'){
                    if(this.deliveryMethod){
                        this.title = this.deliveryMethod;
                    } else {
                        this.title = 'Document';
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
